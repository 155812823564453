<template>
  <div
    v-if="studentSelectionInRiskAssessmentEnabled && !isCreatedWithExcursionManagement"
    class="category-div student-selection"
  >
    <v-dialog
      ref="studentSelectionDialog"
      v-model="studentSelectionDialogDisplayed"
      width="650"
      persistent
    >
      <v-card>
        <v-card-text>
          <br />
          <v-checkbox
            v-model="filterStudentShowSelectedOnly"
            label="Show selected students only"
            dense
            hide-details="true"
            style="margin-bottom: 10px"
            :disabled="isLoading"
          ></v-checkbox>
          <v-text-field
            v-model="filterStudentText"
            solo
            dense
            clearable
            placeholder="Filter student list"
            :disabled="isLoading"
          />
          <v-data-table
            :headers="[
              { text: 'Student ID', value: 'id', width: '20%' },
              { text: 'Student Name', value: 'name', width: '50%' },
              { text: 'Groups', value: 'group', width: '30%' }
            ]"
            density="dense"
            v-model="selectedStudents"
            :items="filteredStudentOptions"
            :items-per-page="-1"
            item-key="id"
            :sort-by="['id']"
            :sort-desc="[false]"
            show-select
            :search="filterStudentText"
            checkbox-color="primary"
            :loading="isLoading"
            :disabled="isLoading"
          ></v-data-table>
          <div style="text-align: center">
            Last List Updated At: {{ formatedStudentDataTimestamp }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            id="student-selection--btn-refresh"
            class="normal-btn white--text secondary"
            text
            @click="refreshStudentSelection"
            :disabled="isLoading || !formControlEnabled"
            >Update List</v-btn
          >
          <v-icon v-if="isLoading" medium style="height: 8px"> fa fa-spinner fa-pulse </v-icon>
          <v-spacer></v-spacer>
          <v-btn
            id="student-selection--btn-confirm"
            class="normal-btn white--text primary"
            text
            @click="onClickConfirm"
            :disabled="isLoading || !formControlEnabled"
            >Confirm</v-btn
          >
          <v-btn
            id="student-selection--btn-cancel"
            class="normal-btn white--text secondary"
            text
            @click="onClickCancel"
            :disabled="isLoading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout align-center justify-space-between style="display: flex">
      <div>
        <v-layout align-center justify-space-between class="category-section">
          <v-icon id="student-selection--toggle--icon" class="icon" @click.stop="toggle()">
            {{ !studentSelectionContentDisplayed ? 'keyboard_arrow_left' : 'keyboard_arrow_down' }}
          </v-icon>
          <div id="student-selection--toggle--label" class="name" @click.stop="toggle()">
            Students
          </div>
          <div class="quantity ml-3">{{ totalSelectedStudents }} In Total</div>
        </v-layout>
      </div>
      <v-flex>
        <div class="d-flex justify-end align-center">
          <div style="width: 231px">
            <v-checkbox
              id="student-selection--chk-group-other"
              v-model="otherGroupEnabled"
              label="Co-curricular and Sport Groups"
              dense
              hide-details="true"
              :disabled="isLoading || !formControlEnabled"
            ></v-checkbox>
          </div>
          <v-btn
            id="student-selection--btn-select"
            class="normal-btn"
            @click="openStudentSelection"
            :disabled="
              otherGroupEnabled
                ? selectedGroupOthers.length === 0
                : selectedGroupClasses.length === 0
            "
            >{{ formControlEnabled ? 'Select' : 'View' }} Students</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
    <div :hidden="!studentSelectionContentDisplayed" class="sub-category-section">
      <div :hidden="otherGroupEnabled">
        <!-- SCHOOL -->
        <v-flex xs12 class="sub-label">
          <v-layout align-center justify-space-between>
            <div>School</div>
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 class="input-container">
          <v-autocomplete
            id="student-selection--group-school"
            ref="selectGroupSchool"
            v-model="selectedGroupSchools"
            label="Select one or more schools"
            :items="[{ id: 'all', name: 'Select All' }, ...groupSchoolOptions]"
            item-text="name"
            item-value="id"
            :multiple="true"
            clearable
            chips
            :deletable-chips="true"
            hide-details
            :hide-selected="autocompleteHideSelected"
            solo
            :loading="isLoading"
            loading-text="Loading..."
            :disabled="isLoading || !formControlEnabled"
            @change="
              (values) => handleGroupSelectionChange(values, $refs.selectGroupSchool, 'school')
            "
          >
            <template slot="item" slot-scope="data">
              <span :id="`student-selection--group-school-item-${data.item.id}`">
                {{ data.item.name }}
              </span>
            </template>
          </v-autocomplete>
        </v-flex>
        <!-- YEAR -->
        <v-flex xs12 class="sub-label">
          <v-layout align-center justify-space-between>
            <div>Year</div>
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 class="input-container">
          <v-autocomplete
            id="student-selection--group-year"
            ref="selectGroupYear"
            v-model="selectedGroupYears"
            label="Select one or more years"
            :items="[{ id: 'all', name: 'Select All' }, ...groupYearOptions]"
            item-text="name"
            item-value="id"
            :multiple="true"
            clearable
            chips
            :deletable-chips="true"
            hide-details
            :hide-selected="autocompleteHideSelected"
            solo
            :loading="isLoading"
            loading-text="Loading..."
            :disabled="isLoading || !formControlEnabled || selectedGroupSchools.length === 0"
            @change="(values) => handleGroupSelectionChange(values, $refs.selectGroupYear, 'year')"
          >
            <template slot="item" slot-scope="data">
              <span :id="`student-selection--group-year-item-${data.item.id}`">
                {{ data.item.name }}
              </span>
            </template>
          </v-autocomplete>
        </v-flex>
        <!-- CLASS -->
        <v-flex xs12 class="sub-label">
          <v-layout align-center justify-space-between>
            <div>Class</div>
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 class="input-container">
          <v-autocomplete
            id="student-selection--group-class"
            ref="selectGroupClass"
            v-model="selectedGroupClasses"
            label="Select one or more classes"
            :items="[{ id: 'all', name: 'Select All' }, ...groupClassOptions]"
            item-text="name"
            item-value="id"
            :multiple="true"
            clearable
            chips
            :deletable-chips="true"
            hide-details
            :hide-selected="autocompleteHideSelected"
            solo
            :loading="isLoading"
            loading-text="Loading..."
            :disabled="isLoading || !formControlEnabled || selectedGroupYears.length === 0"
            @change="
              (values) => handleGroupSelectionChange(values, $refs.selectGroupClass, 'class')
            "
          >
            <template slot="item" slot-scope="data">
              <span :id="`student-selection--group-class-item-${data.item.id}`">
                {{ data.item.name }}
              </span>
            </template>
          </v-autocomplete>
        </v-flex>
      </div>
      <div :hidden="!otherGroupEnabled">
        <!-- OTHER -->
        <v-flex xs12 class="sub-label">
          <v-layout align-center justify-space-between>
            <div>Co-curricular and Sport</div>
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 class="input-container">
          <v-autocomplete
            id="student-selection--group-other"
            ref="selectGroupOther"
            v-model="selectedGroupOthers"
            label="Select one or more other groups"
            :items="[{ id: 'all', name: 'Select All' }, ...groupOtherOptions]"
            item-text="name"
            item-value="id"
            :multiple="true"
            clearable
            chips
            :deletable-chips="true"
            hide-details
            :hide-selected="autocompleteHideSelected"
            solo
            :loading="isLoading"
            loading-text="Loading..."
            :disabled="isLoading || !formControlEnabled"
            @change="
              (values) => handleGroupSelectionChange(values, $refs.selectGroupOther, 'other')
            "
          >
            <template slot="item" slot-scope="data">
              <span :id="`student-selection--group-other-item-${data.item.id}`">
                {{ data.item.name }}
              </span>
            </template>
          </v-autocomplete>
        </v-flex>
      </div>
    </div>
    <div>
      <cs-form-dialog
        v-model="warningStudentSelectionDialogDisplayed"
        id="warning-student-selection-dialog"
        heading="Student Selection"
        :primaryAction="{
          label: 'Continue',
          eventName: 'onClickContinue'
        }"
        @onClickContinue="warningStudentSelectionDialogContinue"
      >
        <template v-slot:cs-form-dialog-content>
          <div>
            <span>{{ warningStudentSelectionDialogMessage }}</span>
            <br /><br />
            <span>Do you still want to proceed?</span>
          </div>
        </template>
      </cs-form-dialog>
    </div>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';

export default {
  props: ['formControlEnabled'],
  async mounted() {
    await this.getData();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      clientData: null,
      groupData: null,
      studentData: [],
      studentSelectionInRiskAssessmentEnabled: true,
      studentSelectionContentDisplayed: true,
      studentSelectionDialogDisplayed: false,
      otherGroupEnabled: false,
      selectStudentEnabled: false,
      selectedGroupSchools: [],
      selectedGroupYears: [],
      selectedGroupClasses: [],
      selectedGroupOthers: [],
      autocompleteHideSelected: false,
      studentOptions: [],
      selectedStudents: [],
      studentDataTimestamp: '',
      filterStudentText: '',
      filterStudentShowSelectedOnly: false,
      warningStudentSelectionDialogDisplayed: false,
      warningEmptyStudentSelectionDialogDisplayed: false,
      warningCancelStudentSelectionDialogDisplayed: false
    };
  },
  computed: {
    impersonatedClientSid() {
      return this.$store.state.common.impersonatedClientSid;
    },
    clientSid() {
      return this.$store.state.common.userClientSid;
    },
    excursionId() {
      return this.$store.state.common.excursionDc?.id;
    },
    apiParam() {
      return this.$store.state.common.apiParam;
    },
    isCreatedWithExcursionManagement() {
      return this.$store.state.common.excursionDc?.vendorData?.vendorExcursionSid?.length > 0;
    },
    groupSchoolOptions() {
      let retValues = [];

      if (this.clientData?.schoolType) {
        retValues = Object.entries(this.clientData.schoolType).map(([id, name]) => ({ id, name }));
      }

      return retValues;
    },
    groupYearOptions() {
      if (this.groupData?.associationHash) {
        const options = Object.entries(this.groupData.associationHash.year)
          .map(([id]) => {
            const { parentId } = this.groupData.entryHash[id];
            const { name } = this.groupData.entryHash[id];

            if (this.selectedGroupSchools.indexOf(parentId) !== -1) {
              return { id, name };
            }

            return null;
          })
          .filter((item) => !!item);

        return options;
      }

      return [];
    },
    groupClassOptions() {
      if (this.groupData?.associationHash) {
        const options = Object.entries(this.groupData.associationHash.class)
          .map(([id]) => {
            const { parentId } = this.groupData.entryHash[id];
            const { name } = this.groupData.entryHash[id];

            if (this.selectedGroupYears.indexOf(parentId) !== -1) {
              return { id, name };
            }

            return null;
          })
          .filter((item) => !!item);

        return options;
      }

      return [];
    },
    groupOtherOptions() {
      let retValues = [];

      if (this.groupData?.associationHash) {
        retValues = Object.entries(this.groupData.associationHash.custom).map(([id]) => {
          const { name } = this.groupData.entryHash[id];
          return { id, name };
        });
      }

      return retValues;
    },
    filteredStudentOptions() {
      let retValues = [];

      if (this.filterStudentShowSelectedOnly) {
        retValues = this.selectedStudents;
      } else {
        retValues = this.studentOptions;
      }

      return retValues;
    },
    formatedStudentDataTimestamp() {
      let retValue = 'Never';

      if (this.studentDataTimestamp.length > 0) {
        retValue = this.$app
          .moment(this.studentDataTimestamp)
          .utc()
          .tz(this.$store.state.common.clientTimezone)
          .format('MM/DD/YYYY, hh:mm:ss A');
      }

      return retValue;
    },
    confirmedStudentInfo: {
      get() {
        const defaultValue = {
          studentLastUpdatedAt: '',
          studentDetailHash: {},
          selectionHash: {
            student: [],
            school: [],
            year: [],
            class: [],
            custom: []
          }
        };

        return this.$store.state.common.excursionDc.studentInfo || defaultValue;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_STUDENT_INFO, value);
      }
    },
    totalSelectedStudents() {
      return this.confirmedStudentInfo.selectionHash.student.length;
    },
    studentAttendanceCount: {
      get() {
        return this.$store.state.common.excursionDc.studentAttendanceCount;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_STUDENT_ATTENDANCE_COUNT, value);
      }
    },
    warningStudentSelectionDialogMessage() {
      return this.warningEmptyStudentSelectionDialogDisplayed
        ? 'No students have been selected, so no medical conditions will be retrieved.'
        : 'Cancel will revert all selected groups and students to the last confirmed selection.';
    },
    warningStudentSelectionDialogContinue() {
      return this.warningEmptyStudentSelectionDialogDisplayed
        ? this.confirmStudentSelection
        : this.cancelStudentSelection;
    }
  },
  watch: {
    warningStudentSelectionDialogDisplayed(value) {
      if (!value) {
        this.warningEmptyStudentSelectionDialogDisplayed = false;
        this.warningCancelStudentSelectionDialogDisplayed = false;
      }
    }
  },
  methods: {
    async getData() {
      if (this.impersonatedClientSid) {
        await this.getDataAsSysAdmin();
      } else {
        await this.getDataAsNonSysAdmin();
      }
    },
    async getDataAsSysAdmin() {
      const _ = this.$app.lodash;
      const requestParam = {
        'vendor-sid': this.apiParam['vendor-sid'],
        'oauth-signature': this.apiParam['oauth-signature']
      };

      const getClientsArgHash = {
        sid: this.clientSid,
        requestParam,
        store: this.$store
      };

      const cbGetClientsResponse = await this.$app.stbApiAdapter.getClients(getClientsArgHash);

      if (!cbGetClientsResponse.error && cbGetClientsResponse.data) {
        const client = cbGetClientsResponse.data[0];
        const { activeStudentManagementVendorSid } = client.metadata;
        const { vendorList } = client;
        const studentManagementVendor = _.find(vendorList, {
          sid: activeStudentManagementVendorSid
        });
        const groupData =
          studentManagementVendor?.join_metadata?.studentManagement.clientApiMetadata
            .groupManagement;

        this.clientData = {
          schoolType: client.metadata.schoolType,
          subdomain: ''
        };
        this.studentSelectionInRiskAssessmentEnabled =
          studentManagementVendor?.join_metadata?.studentManagement.clientApiMetadata.studentSelectionInRiskAssessmentEnabled;

        if (this.studentSelectionInRiskAssessmentEnabled && groupData) {
          this.groupData = groupData;
          this.selectedGroupSchools = this.confirmedStudentInfo.selectionHash.school;
          this.selectedGroupYears = this.confirmedStudentInfo.selectionHash.year;
          this.selectedGroupClasses = this.confirmedStudentInfo.selectionHash.class;
          this.selectedGroupOthers = this.confirmedStudentInfo.selectionHash.custom;
          this.otherGroupEnabled = this.confirmedStudentInfo.selectionHash.custom.length > 0;

          await this.$nextTick();
          this.autocompleteHideSelected = true;
        }
      } else {
        console.error('CLIENT_STUDENT_GROUPS', 'Failed to retrieve client student groups');
      }
    },
    async getDataAsNonSysAdmin() {
      const cbGetExcursionClientSetup = await this.$app.stbApiAdapter.getClientSetups({
        queryParam: {
          'excursion-id': this.excursionId
        }
      });

      if (!cbGetExcursionClientSetup.error && cbGetExcursionClientSetup.data) {
        this.studentSelectionInRiskAssessmentEnabled =
          cbGetExcursionClientSetup.data.computed?.studentSelectionInRiskAssessmentEnabled;
        this.clientData = {
          schoolType: cbGetExcursionClientSetup.data.computed?.clientSchoolType,
          subdomain: cbGetExcursionClientSetup.data.computed?.clientSubdomain
        };

        if (this.studentSelectionInRiskAssessmentEnabled) {
          const cbGetClientStudentGroups = await this.$app.stbApiAdapter.getClientStudentGroups(
            this.clientData.subdomain
          );

          if (!cbGetClientStudentGroups.error && cbGetClientStudentGroups.data) {
            this.groupData = cbGetClientStudentGroups.data;
            this.selectedGroupSchools = this.confirmedStudentInfo.selectionHash.school;
            this.selectedGroupYears = this.confirmedStudentInfo.selectionHash.year;
            this.selectedGroupClasses = this.confirmedStudentInfo.selectionHash.class;
            this.selectedGroupOthers = this.confirmedStudentInfo.selectionHash.custom;
            this.otherGroupEnabled = this.confirmedStudentInfo.selectionHash.custom.length > 0;

            await this.$nextTick();
            this.autocompleteHideSelected = true;
          } else {
            console.error('CLIENT_STUDENT_GROUPS', 'Failed to retrieve client student groups');
          }
        }
      } else {
        console.error('CLIENT_SETUP', 'Failed to retrieve client excursion setup');
      }
    },
    toggle() {
      this.studentSelectionContentDisplayed = !this.studentSelectionContentDisplayed;
      this.$emit('toggle', this.studentSelectionContentDisplayed);
    },
    async handleGroupSelectionChange(values, ref, type) {
      ref.blur();
      this.autocompleteHideSelected = false;

      await this.$nextTick();

      switch (type) {
        case 'school':
          if (values.includes('all')) {
            this.selectedGroupSchools = this.groupSchoolOptions.map((item) => item.id);
          }
          this.selectedGroupYears = [];
          this.selectedGroupClasses = [];
          this.selectedGroupOthers = [];
          break;

        case 'year':
          if (values.includes('all')) {
            this.selectedGroupYears = this.groupYearOptions.map((item) => item.id);
          }
          this.selectedGroupClasses = [];
          break;

        case 'class':
          if (values.includes('all')) {
            this.selectedGroupClasses = this.groupClassOptions.map((item) => item.id);
          }
          break;

        case 'other':
          if (values.includes('all')) {
            this.selectedGroupOthers = this.groupOtherOptions.map((item) => item.id);
          }
          this.selectedGroupSchools = [];
          this.selectedGroupYears = [];
          this.selectedGroupClasses = [];
          break;

        default:
          this.selectedGroupSchools = [];
          this.selectedGroupYears = [];
          this.selectedGroupClasses = [];
          this.selectedGroupOthers = [];
      }

      await this.$nextTick();

      ref.focus();
      this.autocompleteHideSelected = true;
    },
    async openStudentSelection() {
      this.filterStudentText = '';
      this.filterStudentShowSelectedOnly = false;

      this.studentOptions = Object.entries(this.confirmedStudentInfo.studentDetailHash).map(
        ([id, item]) => {
          const { name } = item;
          const group = item.groups
            .map((groupId) => this.groupData.entryHash[groupId].name)
            .join(', ');

          return { id, name, group };
        }
      );

      this.selectedStudents = this.confirmedStudentInfo.selectionHash.student.map((id) => {
        const studentDetail = this.confirmedStudentInfo.studentDetailHash[id];
        const { name } = studentDetail;
        const group = studentDetail.groups
          .map((groupId) => this.groupData.entryHash[groupId].name)
          .join(', ');

        return { id, name, group };
      });

      this.studentData = Object.entries(this.confirmedStudentInfo.studentDetailHash).map(
        ([id, item]) => {
          const { name } = item;
          const { groups } = item;

          return { id, name, groups };
        }
      );

      this.studentDataTimestamp = this.confirmedStudentInfo.studentLastUpdatedAt;
      this.studentSelectionDialogDisplayed = true;

      await this.$nextTick();

      const _ = this.$app.lodash;
      const isGroupSchoolChanged = !_.isEqual(
        [...this.selectedGroupSchools].sort(),
        [...this.confirmedStudentInfo.selectionHash.school].sort()
      );
      const isGroupYearChanged = !_.isEqual(
        [...this.selectedGroupYears].sort(),
        [...this.confirmedStudentInfo.selectionHash.year].sort()
      );
      const isGroupClassChanged = !_.isEqual(
        [...this.selectedGroupClasses].sort(),
        [...this.confirmedStudentInfo.selectionHash.class].sort()
      );
      const isGroupOtherChanged = !_.isEqual(
        [...this.selectedGroupOthers].sort(),
        [...this.confirmedStudentInfo.selectionHash.custom].sort()
      );

      if (
        this.studentDataTimestamp.length === 0 ||
        isGroupSchoolChanged ||
        isGroupYearChanged ||
        isGroupClassChanged ||
        isGroupOtherChanged
      ) {
        await this.refreshStudentSelection();
      }
    },
    async refreshStudentSelection() {
      this.isLoading = true;

      await this.$nextTick();

      const groups = this.otherGroupEnabled ? this.selectedGroupOthers : this.selectedGroupClasses;
      const cbGetStudentsResponse = await this.$app.stbApiAdapter.getClientStudentByGroups(
        this.clientSid,
        this.excursionId,
        groups
      );

      if (!cbGetStudentsResponse.error && cbGetStudentsResponse.data) {
        const _ = this.$app.lodash;
        const studentData = cbGetStudentsResponse.data;
        const groupedStudents = _.groupBy(
          studentData,
          (student) => `${student.id}_${student.fullName}`
        );

        const uniqueStudentData = Object.entries(groupedStudents).map(([, students]) => {
          const { id } = students[0];
          const name = students[0].fullName;
          const combinedGroups = students.map((student) => student.groupId);

          return { id, name, groups: combinedGroups };
        });

        const studentOptions = uniqueStudentData.map((student) => {
          const { id } = student;
          const { name } = student;
          const group = student.groups
            .map((groupId) => this.groupData.entryHash[groupId].name)
            .join(', ');

          return { id, name, group };
        });

        const studentDataTimestamp = new Date().toISOString();

        const updatedStudentDetailHash = uniqueStudentData.reduce((map, item) => {
          return { ...map, [item.id]: item };
        }, {});

        const updateSelectedStudents = this.selectedStudents
          .map((item) => {
            const studentDetail = updatedStudentDetailHash[item.id];

            if (studentDetail) {
              const { id } = item;
              const { name } = studentDetail;
              const group = studentDetail.groups
                .map((groupId) => this.groupData.entryHash[groupId].name)
                .join(', ');

              return { id, name, group };
            }

            return null;
          })
          .filter((item) => !!item);

        this.studentData = uniqueStudentData;
        this.studentOptions = studentOptions;
        this.studentDataTimestamp = studentDataTimestamp;
        this.selectedStudents = updateSelectedStudents;
      } else {
        console.error('CLIENT_STUDENT', 'Failed to retrieve student list');
      }

      this.isLoading = false;
    },
    onClickConfirm() {
      if (this.selectedStudents.length === 0) {
        this.warningEmptyStudentSelectionDialogDisplayed = true;
        this.warningStudentSelectionDialogDisplayed = true;
        return;
      }

      this.confirmStudentSelection();
    },
    async confirmStudentSelection() {
      this.warningStudentSelectionDialogDisplayed = false;
      this.studentSelectionDialogDisplayed = false;

      const newConfirmedStudentInfo = {
        studentLastUpdatedAt: this.studentDataTimestamp,
        studentDetailHash: this.studentData.reduce((map, item) => {
          return { ...map, [item.id]: item };
        }, {}),
        selectionHash: {
          student: this.selectedStudents.map((item) => item.id),
          school: this.selectedGroupSchools,
          year: this.selectedGroupYears,
          class: this.selectedGroupClasses,
          custom: this.selectedGroupOthers
        }
      };

      this.studentDataTimestamp = newConfirmedStudentInfo.studentLastUpdatedAt;
      this.confirmedStudentInfo = newConfirmedStudentInfo;
      this.studentAttendanceCount = newConfirmedStudentInfo.selectionHash.student.length || '';

      this.$emit('save');
    },
    onClickCancel() {
      this.warningCancelStudentSelectionDialogDisplayed = true;
      this.warningStudentSelectionDialogDisplayed = true;
    },
    async cancelStudentSelection() {
      this.autocompleteHideSelected = false;
      await this.$nextTick();

      this.warningStudentSelectionDialogDisplayed = false;
      this.studentSelectionDialogDisplayed = false;

      this.selectedStudents = this.confirmedStudentInfo.selectionHash.student.map((id) => {
        const studentDetail = this.confirmedStudentInfo.studentDetailHash[id];
        const { name } = studentDetail;
        const group = studentDetail.groups
          .map((groupId) => this.groupData.entryHash[groupId].name)
          .join(', ');

        return { id, name, group };
      });

      this.selectedGroupSchools = this.confirmedStudentInfo.selectionHash.school;
      this.selectedGroupYears = this.confirmedStudentInfo.selectionHash.year;
      this.selectedGroupClasses = this.confirmedStudentInfo.selectionHash.class;
      this.selectedGroupOthers = this.confirmedStudentInfo.selectionHash.custom;
      this.otherGroupEnabled = this.confirmedStudentInfo.selectionHash.custom.length > 0;
      this.studentDataTimestamp = this.confirmedStudentInfo.studentLastUpdatedAt;

      await this.$nextTick();
      this.autocompleteHideSelected = true;
    }
  }
};
</script>
